:root {
  --pink: #ffc0cb;
  --dark-pink: #f1948a;
  --peach: #ffd1b3;
  --lavender: #e6e6fa;
  --dark: #333;
  --light: #fff;
  --shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  --accent: #555;
  --gradient: linear-gradient(45deg, #ffc0cb, #e6e6fa, #ffd1b3);
}

body {
  font-family: 'Playfair Display', serif;
  background-color: var(--light);
  color: var(--dark);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
}

* {
  box-sizing: border-box;
}

h1, h2, h3 {
  font-family: 'Raleway', sans-serif;
  color: var(--dark-pink);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  margin: 20px 0;
}

/* Decorative lines under headers */
h1::after, h2::after, h3::after {
  content: '';
  display: block;
  width: 50px;
  height: 2px;
  background-color: var(--dark-pink);
  margin: 10px auto;
}

/* Navigation */
nav {
  background: var(--lavender);
  padding: 1.5rem;
  box-shadow: var(--shadow);
  border-bottom: 3px solid var(--dark-pink);
  position: sticky;
  top: 0;
  z-index: 100;
}

nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
}

nav ul li {
  margin: 0 20px;
}

nav ul li a {
  font-family: 'Raleway', sans-serif;
  color: var(--dark);
  text-transform: uppercase;
  letter-spacing: 2px;
  text-decoration: none;
  padding: 10px 15px;
  transition: all 0.3s ease;
}

nav ul li a:hover, nav ul li a:focus {
  color: var(--dark-pink);
  border-bottom: 2px solid var(--dark-pink);
}

/* Content area */
.content {
  padding: 40px 5vw; /* Flexible padding for responsiveness */
  max-width: 1200px;
  margin: 50px auto;
  background-color: var(--light);
  box-shadow: var(--shadow);
  border: 3px solid var(--lavender);
  border-radius: 15px;
}

button, .button {
  background-color: var(--peach);
  color: var(--dark);
  border: 2px solid var(--dark-pink);
  padding: 12px 24px;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50px; /* Rounded corners for a luxurious look */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: inline-block;
}

button:hover, .button:hover {
  background-color: var(--dark-pink); /* Change to darker pink on hover */
  color: var(--light);
  transform: translateY(-3px); /* Slight lift effect */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

input, textarea {
  width: 100%;
  max-width: 800px;
  padding: 15px;
  margin: 15px auto;
  border: 2px solid var(--lavender);
  background-color: rgba(255, 192, 203, 0.1);
  border-radius: 8px;
  font-family: 'Playfair Display', serif;
  font-size: 16px;
  display: block;
}

input:focus, textarea:focus {
  outline: none;
  border-color: var(--dark-pink);
  box-shadow: 0 0 10px rgba(241, 148, 138, 0.4);
}

/* Footer */
footer {
  text-align: center;
  padding: 1.5rem;
  background-color: var(--dark);
  color: var(--light);
  font-family: 'Playfair Display', serif;
  letter-spacing: 1px;
  border-top: 3px solid var(--dark-pink);
}

/* Art Deco patterns and accents */
.deco-border {
  border: 4px solid var(--lavender);
  border-image: linear-gradient(to right, var(--pink), var(--lavender), var(--peach)) 1;
  padding: 20px;
  border-radius: 10px;
}

.decorative-divider {
  height: 2px;
  background: var(--gradient);
  margin: 20px auto;
  width: 80%;
}

/* Responsive typography */
h1 {
  font-size: 5vw; /* Scale header size based on viewport width */
}

h2 {
  font-size: 4vw;
}

h3 {
  font-size: 3vw;
}

p {
  font-size: 1.2rem;
}

/* Elegant Hamburger Menu */
.hamburger {
  display: none; /* Hidden by default */
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 22px;
  z-index: 200;
}

.hamburger .bar {
  width: 100%;
  height: 3px;
  background-color: var(--dark-pink);
  border-radius: 10px; /* Rounded edges */
  transition: all 0.4s ease;
}

/* Animate hamburger menu open/close */
.hamburger .bar.open:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger .bar.open:nth-child(2) {
  opacity: 0;
}

.hamburger .bar.open:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Mobile Nav: Open/Close */
.nav-open {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: var(--lavender);
  transition: all 0.5s ease-in-out;
  padding: 20px;
  position: absolute;
  top: 60px; /* Align below the header */
  left: 0;
  right: 0;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Media Queries */
@media (max-width: 768px) {
  nav ul {
    flex-direction: column;
  }

  .hamburger {
    display: flex;
  }

  nav ul {
    display: none; /* Hide normal nav on mobile */
  }

  nav ul.nav-open {
    display: flex; /* Show nav when menu is open */
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: var(--light);
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Soft shadow */
  }

  nav ul li button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: var(--peach);
    color: var(--dark);
    border: none;
    border-radius: 20px;
    transition: all 0.3s ease;
    box-shadow: var(--shadow);
  }

  nav ul li button:hover {
    background-color: var(--dark-pink);
    color: var(--light);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  button {
    padding: 10px 20px;
    font-size: 3.5vw;
  }

  .content {
    padding: 20px 4vw;
  }

  input, textarea {
    width: 100%;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 6vw;
  }

  h2 {
    font-size: 5vw;
  }

  h3 {
    font-size: 4vw;
  }

  button {
    font-size: 4vw;
    padding: 8px 16px;
  }

  .content {
    padding: 10px 2vw;
  }

  input, textarea {
    font-size: 14px;
  }
}
/* Hero Section Styling */
.hero {
  position: relative;
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../public/images/rjuhs.png'); /* Add your image path here */
  background-size: cover; /* Ensures the image covers the entire section */
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  color: #fff; /* Set text color to contrast with the background */
  text-align: center;
  padding: 0 20px;
}

.hero-content {
  z-index: 2; /* Ensures content is above the background */
}

.hero h1 {
  font-size: 3.5rem;
  font-family: 'Playfair Display', serif;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5); /* Subtle shadow for contrast */
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.5rem;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 40px;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5); /* Subtle shadow for readability */
}

.cta-button {
  background-color: #f1948a;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 30px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cta-button:hover {
  background-color: #ffb5a7;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transform: translateY(-5px);
}

/* Overlay Effect */
.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4); /* Dark overlay to improve text contrast */
  z-index: 1; /* Ensure the overlay is behind the content */
}

/* Responsive Styling */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1.2rem;
  }

  .cta-button {
    padding: 12px 25px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}
