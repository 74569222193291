/* Testimonials Section Styling */
#testimonials {
  max-width: 1000px;
  margin: 60px auto;
  padding: 60px 20px;
  background: linear-gradient(145deg, #ffd1b3, #f5b7b1);
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #333;
  font-family: 'Raleway', sans-serif;
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease;
}

#testimonials h2 {
  font-size: 2.8rem;
  margin-bottom: 30px;
  font-weight: 700;
  color: #f1948a;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
  position: relative;
}

#testimonials h2::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background-color: #f1948a;
  margin: 15px auto;
  border-radius: 2px;
  opacity: 0.9;
}

.testimonial-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}

/* Individual Testimonial Styling */
.testimonial {
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
  border: 2px solid #f5b7b1;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: all 0.4s ease;
  position: relative;
}

.testimonial:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.testimonial-text {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555;
  font-style: italic;
}

.testimonial-author {
  font-weight: 700;
  color: #f1948a;
  margin-top: 15px;
  text-align: right;
}

/* Background Artistic Effects */
#testimonials::before {
  content: '';
  position: absolute;
  top: -100px;
  right: -100px;
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, rgba(241, 148, 138, 0.2), transparent);
  border-radius: 50%;
  z-index: 0;
}

#testimonials::after {
  content: '';
  position: absolute;
  bottom: -100px;
  left: -100px;
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, rgba(245, 183, 177, 0.3), transparent);
  border-radius: 50%;
  z-index: 0;
}

/* Responsive Styling */
@media (max-width: 768px) {
  #testimonials h2 {
      font-size: 2.5rem;
  }

  .testimonial {
      padding: 20px;
  }

  .testimonial-text {
      font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  #testimonials h2 {
      font-size: 2rem;
  }

  .testimonial {
      padding: 18px;
  }

  .testimonial-text {
      font-size: 1rem;
  }

  .testimonial-author {
      font-size: 1rem;
  }
}
