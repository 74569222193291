/* Contact Section Styling */
.contact-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 20px;
  background: linear-gradient(145deg, #ffd1b3, #f5b7b1);
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #333;
  font-family: 'Raleway', sans-serif;
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease;
}

.contact-page h2 {
  font-size: 2.8rem;
  margin-bottom: 30px;
  font-weight: 700;
  color: #f1948a;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
  position: relative;
}

.contact-page h2::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background-color: #f1948a;
  margin: 15px auto;
  border-radius: 2px;
  opacity: 0.9;
}

/* Contact Icons and Text */
.contact-icons {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 30px;
}

.contact-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-size: 1.2rem;
  color: #333;
  transition: all 0.4s ease;
  padding: 20px;
  border: 2px solid #f5b7b1;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  position: relative;
}

.contact-item:hover {
  background-color: #f1948a;
  color: #fff;
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.contact-item p a {
  color: #f1948a;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.contact-item p a:hover {
  color: #fff;
}

.contact-item:hover p a {
  color: #fff; /* Ensure links remain visible on hover */
}

/* Icon Styling */
.contact-item svg {
  color: #f1948a;
  transition: color 0.4s ease;
}

.contact-item:hover svg {
  color: #fff;
  transform: scale(1.2); /* Slight zoom effect on hover */
}

/* Background Design Elements for Artistic Effect */
.contact-page::before {
  content: '';
  position: absolute;
  top: -150px;
  left: -150px;
  width: 400px;
  height: 400px;
  background: radial-gradient(circle, rgba(241, 148, 138, 0.2), transparent);
  border-radius: 50%;
  z-index: 0;
}

.contact-page::after {
  content: '';
  position: absolute;
  bottom: -150px;
  right: -150px;
  width: 400px;
  height: 400px;
  background: radial-gradient(circle, rgba(245, 183, 177, 0.3), transparent);
  border-radius: 50%;
  z-index: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-icons {
      gap: 30px;
  }

  .contact-item {
      padding: 15px;
      font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .contact-page h2 {
      font-size: 2rem;
  }

  .contact-item {
      flex-direction: column;
      gap: 10px;
      padding: 15px;
      font-size: 1rem;
  }

  .contact-item svg {
      font-size: 1.5rem;
  }
}
